import React, { useContext, useState, useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
// import windowSize from 'react-window-size';
import { Layout, Seo } from 'components';

import { appContext } from 'providers/appProvider';

import SourceEmitter from 'libs/emitter';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import './signup.scss';
import Form from './form';

const SignUp = props => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  // const metaData = appConfigs?.metaData?.nutritionPatient;

  // INITIALIZATIONS
  let Subscription = null;
  let SubscriptionFormSubmit = null;

  useEffect(() => {
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });

    goToAnchor('top');
    SubscriptionFormSubmit = SourceEmitter.addListener(
      'FormSubmitted',
      data => {
        if (data) {
          setFormSubmitted(true);
          goToAnchor('top');
        }
      },
    );

    return () => {
      Subscription && Subscription.remove();
      SubscriptionFormSubmit && SubscriptionFormSubmit.remove();
    };
  }, []);

  // const brandNames = {
  //   NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
  //   BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
  //   NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  // };

  const pageTitle = 'NUTRITION DIRECT™ | Sign Up';
  const pageDescription = 'Sign up for updates and resources';

  const renderPage = () => {
    return (
      <div id="nutrition-patient-signup">
        <ScrollableAnchor id="top">
          <div />
        </ScrollableAnchor>
        <ContentBlock id="cblock-vitaminb12">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <ColorBlock white>
                  <div className="signup-form-container">
                    {!formSubmitted ? (
                      <div>
                        <h2>
                          NASCOBAL<sup className="brand-reg-mark">&reg;</sup>
                          /Nutrition Direct
                          <sup className="brand-reg-mark">&trade;</sup> Consumer
                          Sign-up Form
                        </h2>
                        <p className="strong">
                          Thanks for your interest in NASCOBAL
                          <sup className="brand-reg-mark">&reg;</sup> and
                          Nutrition Direct
                          <sup className="brand-reg-mark">&trade;</sup>.
                        </p>
                        <p>
                          Register to receive tools, resources, and valuable
                          information.
                        </p>
                        <p>
                          Please complete the form below to help us determine
                          the best information for you going forward. You can
                          unsubscribe at any time.
                        </p>
                        <p style={{ paddingTop: 15, fontWeight: 'bold' }}>
                          All fields are required unless marked optional.
                        </p>
                        <Form />
                      </div>
                    ) : (
                      <div>
                        <h2>THANK YOU</h2>
                        <p>
                          Thank you for signing up to learn more. You will be
                          hearing from us shortly.
                        </p>
                      </div>
                    )}
                  </div>
                </ColorBlock>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div className="page-page-offset" />
        </ScrollableAnchor>
      </div>
    );
  };
  return (
    <Layout indication={indication} className="nutrition-patient-home">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default SignUp;
